import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;
  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 1065.000000 357.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="translate(0.000000,357.000000) scale(0.100000,-0.100000)">
		<path d="M2490 2654 l0 -196 -49 -47 c-81 -76 -83 -164 -5 -238 40 -39 49 -43
95 -43 159 0 226 207 94 293 -35 22 -35 22 -35 99 0 42 4 80 10 83 5 3 37 -6
71 -21 68 -31 128 -91 160 -163 30 -64 30 -197 1 -261 -30 -65 -87 -127 -146
-158 -45 -23 -62 -27 -146 -27 -79 0 -103 4 -142 23 -102 50 -175 157 -177
260 l-1 32 -116 0 -117 0 7 -62 c3 -34 11 -67 16 -72 6 -6 10 -19 10 -30 0
-12 13 -49 29 -83 72 -152 218 -261 399 -298 151 -32 328 20 452 132 133 119
208 323 180 485 -25 143 -84 255 -181 342 -90 81 -254 145 -371 146 l-38 0 0
-196z m76 -312 c25 -16 34 -50 22 -76 -6 -14 -14 -26 -17 -26 -3 0 -16 -3 -28
-6 -25 -7 -63 26 -63 56 0 40 55 73 86 52z"/>
<path d="M2301 2808 c-1 -24 4 -38 12 -38 8 0 7 -4 -3 -10 -11 -7 0 -10 38
-10 l52 0 0 40 0 40 -40 0 c-31 0 -40 -4 -41 -17 -1 -12 -4 -10 -9 7 -7 22 -8
21 -9 -12z"/>
<path d="M2393 2671 c-19 -14 -12 -39 12 -43 22 -5 25 -2 25 22 0 25 -18 35
-37 21z m27 -21 c0 -5 -5 -10 -11 -10 -5 0 -7 5 -4 10 3 6 8 10 11 10 2 0 4
-4 4 -10z"/>
<path d="M2178 2653 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M2188 2633 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M1994 2527 c-3 -9 -4 -30 -2 -49 3 -32 5 -33 51 -36 l48 -3 -3 48 -3
48 -43 3 c-30 2 -44 -1 -48 -11z"/>
<path d="M2177 2445 c-8 -18 11 -31 25 -17 11 11 3 32 -12 32 -4 0 -10 -7 -13
-15z"/>
<path d="M6028 2090 c-66 -36 -64 -99 5 -130 81 -37 206 -20 233 32 18 34 12
57 -21 88 -36 34 -163 40 -217 10z"/>
<path d="M7263 2104 c-12 -3 -34 -16 -48 -30 -23 -21 -26 -31 -22 -61 7 -40
16 -48 67 -63 71 -20 166 -10 199 21 37 34 38 67 3 101 -27 28 -37 31 -104 34
-40 1 -83 0 -95 -2z"/>
<path d="M7725 2090 c-3 -5 -5 -56 -4 -112 1 -94 0 -103 -17 -104 -11 -1 -44
-2 -76 -3 l-56 -1 -1 -70 0 -70 74 0 75 0 0 -316 0 -315 118 3 117 3 3 313 2
312 76 0 75 0 -3 68 -3 67 -55 3 c-101 4 -95 -3 -95 119 l0 108 -112 3 c-69 2
-114 -1 -118 -8z"/>
<path d="M3624 1890 c-47 -7 -124 -41 -164 -73 -17 -14 -69 -81 -88 -115 -21
-38 -42 -144 -41 -217 0 -177 73 -307 208 -372 51 -25 66 -27 171 -27 108 0
118 2 175 31 33 17 80 52 104 79 41 43 43 48 25 55 -10 4 -52 20 -92 34 l-73
27 -20 -26 c-49 -63 -145 -77 -205 -31 -43 33 -63 72 -71 136 l-6 49 257 0
c220 0 257 2 262 15 10 25 -16 176 -40 236 -46 115 -132 180 -262 199 -38 5
-73 9 -79 9 -5 -1 -33 -5 -61 -9z m162 -163 c34 -37 54 -90 54 -144 l0 -33
-138 0 c-76 0 -141 2 -144 5 -9 10 11 97 33 142 27 55 60 75 119 69 35 -3 51
-11 76 -39z"/>
<path d="M6660 1890 c-138 -23 -215 -81 -240 -183 -10 -38 -10 -57 1 -94 23
-84 76 -121 294 -204 33 -13 75 -34 93 -47 28 -20 33 -29 30 -60 -4 -46 -46
-73 -116 -74 -50 0 -116 26 -141 56 -11 13 -20 11 -72 -14 -32 -15 -69 -34
-82 -43 l-22 -15 42 -40 c67 -64 136 -86 273 -87 100 0 124 3 179 25 117 46
178 152 151 263 -23 91 -95 143 -270 199 -131 41 -174 83 -144 140 16 31 43
42 99 39 41 -1 105 -26 117 -45 10 -14 168 39 168 57 0 18 -66 71 -117 94 -48
22 -147 45 -183 42 -8 -1 -35 -5 -60 -9z"/>
<path d="M8480 1890 c-72 -12 -108 -26 -158 -63 -52 -39 -79 -72 -106 -132
-47 -100 -58 -220 -30 -328 33 -129 85 -197 189 -250 56 -29 67 -31 175 -31
114 -1 116 -1 186 36 44 23 85 53 107 80 l35 42 -91 33 -92 33 -20 -23 c-37
-42 -68 -57 -118 -57 -92 0 -145 52 -154 151 l-6 59 254 2 254 3 3 43 c5 63
-23 182 -56 243 -63 119 -219 185 -372 159z m126 -139 c37 -23 71 -88 80 -154
l7 -47 -147 0 -146 0 0 23 c0 38 29 122 51 151 38 49 102 59 155 27z"/>
<path d="M4415 1883 c-86 -21 -154 -85 -203 -190 -25 -55 -26 -67 -26 -208 -1
-134 2 -156 22 -208 13 -31 30 -63 38 -71 8 -8 14 -17 14 -21 0 -12 56 -57 95
-75 83 -40 216 -16 266 49 11 14 28 37 39 51 l19 25 -7 -70 c-4 -38 -7 -122
-7 -185 l0 -115 118 -3 117 -3 0 506 -1 505 -99 0 c-114 0 -106 9 -89 -101 6
-39 9 -72 7 -75 -3 -2 -17 20 -32 50 -53 105 -168 164 -271 139z m193 -183
c52 -32 74 -94 74 -206 -1 -108 -15 -165 -53 -205 -23 -24 -35 -29 -76 -29
-41 0 -54 5 -79 29 -41 40 -54 90 -54 207 0 117 20 172 72 204 42 25 74 25
116 0z"/>
<path d="M5198 1872 l-98 -3 0 -260 c0 -150 4 -278 11 -302 5 -23 27 -67 48
-97 30 -44 51 -62 107 -90 67 -34 72 -35 189 -35 110 0 125 2 176 27 68 32
124 87 153 148 20 42 21 65 24 327 3 257 2 282 -13 285 -10 1 -64 2 -120 0
l-103 -3 -1 -277 c-1 -251 -3 -279 -20 -302 -44 -60 -154 -59 -191 3 -18 30
-20 52 -20 305 0 158 -4 272 -9 272 -5 0 -15 1 -23 3 -7 1 -57 1 -110 -1z"/>
<path d="M6016 1814 c-3 -8 -6 -173 -6 -365 l0 -350 118 3 117 3 0 360 0 360
-111 3 c-95 2 -113 0 -118 -14z"/>
<path d="M7227 1824 c-4 -4 -7 -169 -7 -366 l0 -359 118 3 117 3 0 360 0 360
-111 3 c-60 1 -113 -1 -117 -4z"/>
<path d="M7970 911 c0 -5 -9 -7 -20 -4 -15 4 -20 0 -20 -16 0 -14 -5 -19 -15
-15 -8 4 -15 1 -15 -6 0 -8 -10 -10 -32 -5 -25 5 -29 4 -18 -4 10 -7 11 -11 3
-11 -8 0 -13 -13 -13 -31 0 -22 -5 -32 -20 -36 -11 -3 -17 -9 -15 -14 3 -4 13
-5 22 -2 11 4 14 2 11 -8 -3 -8 -10 -13 -15 -12 -13 2 -10 -51 5 -60 8 -6 12
-42 12 -125 0 -133 14 -185 56 -219 15 -12 45 -25 65 -28 41 -7 129 3 129 15
0 4 -17 4 -38 0 -45 -8 -112 -1 -112 11 0 5 -9 9 -20 9 -11 0 -20 5 -20 10 0
6 -7 10 -15 10 -10 0 -15 10 -15 27 0 26 1 26 15 7 15 -18 16 -17 10 16 -7 35
-7 35 10 12 9 -13 14 -30 10 -42 -5 -16 0 -20 31 -26 25 -5 39 -3 44 6 4 7 15
10 24 7 8 -3 18 -2 21 3 4 6 -4 11 -17 11 -19 1 -18 3 7 9 21 5 24 8 9 9 -35
2 -56 62 -53 155 1 53 -2 81 -9 84 -7 2 -12 13 -12 24 0 17 3 18 15 8 12 -10
15 -9 16 6 1 11 1 23 0 28 -4 17 22 56 36 56 8 0 11 -4 8 -10 -3 -5 3 -10 15
-10 12 0 18 5 15 10 -3 6 -1 10 4 10 6 0 11 -12 11 -26 0 -15 -4 -23 -11 -19
-5 4 -18 0 -27 -8 -9 -8 -24 -17 -32 -20 -11 -4 -10 -6 4 -6 10 -1 25 7 32 17
13 16 15 16 29 -2 14 -19 15 -17 17 25 l1 44 7 -45 8 -45 -2 45 -1 45 -58 -1
c-52 -1 -58 1 -63 23 -4 13 -4 35 -2 48 5 21 1 25 -21 26 l-26 1 24 7 c23 6
40 41 20 41 -5 0 -9 -4 -9 -9z m-28 -91 c6 0 8 8 6 18 -3 11 0 19 8 19 8 0 12
-14 11 -43 -1 -23 -5 -37 -9 -31 -6 9 -11 8 -18 -3 -8 -13 -10 -13 -10 1 0 9
-2 29 -4 45 -2 16 -1 21 1 12 2 -10 9 -18 15 -18z m6 -212 c-1 -51 -3 -66 -5
-38 -3 25 -8 49 -14 55 -12 12 -12 38 1 30 6 -3 10 3 10 14 0 12 2 21 5 21 3
0 4 -37 3 -82z m21 -75 c-1 -61 -3 -77 -8 -53 -7 35 -3 140 5 140 2 0 3 -39 3
-87z m-42 15 c-2 -13 -4 -3 -4 22 0 25 2 35 4 23 2 -13 2 -33 0 -45z m15 -63
c2 -19 0 -35 -4 -35 -4 0 -8 16 -8 35 0 19 2 35 4 35 2 0 6 -16 8 -35z m-72
-45 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z"/>
<path d="M7907 904 c-3 -4 -21 -10 -39 -15 -32 -9 -32 -9 6 -4 39 4 53 11 44
20 -2 3 -7 2 -11 -1z"/>
<path d="M7190 780 c0 -7 7 -26 15 -43 8 -18 28 -66 44 -107 16 -41 30 -69 30
-63 1 13 -43 134 -60 165 -14 27 -11 31 20 25 16 -4 31 -3 34 1 2 4 2 1 0 -6
-1 -8 -3 -23 -4 -35 -1 -19 -3 -18 -14 8 -11 23 -14 25 -14 10 0 -22 28 -78
29 -57 0 7 5 10 12 6 8 -5 9 2 5 26 -4 19 -2 38 4 46 13 16 39 19 39 4 0 -5
-7 -10 -15 -10 -11 0 -14 -12 -14 -50 2 -51 12 -66 22 -30 3 13 14 20 29 20
16 0 19 3 10 9 -7 4 -12 12 -11 17 1 5 -1 21 -5 35 -7 25 -11 27 -73 28 -35 0
-69 3 -74 6 -5 4 -9 1 -9 -5z m150 -66 c0 -8 -4 -14 -10 -14 -5 0 -10 9 -10
21 0 11 5 17 10 14 6 -3 10 -13 10 -21z"/>
<path d="M8620 780 c-14 -4 -35 -5 -48 -2 -21 4 -23 2 -20 -29 2 -24 8 -34 20
-34 31 0 47 -36 48 -107 0 -64 -1 -68 -25 -74 -21 -5 -25 -12 -25 -46 0 -22
-5 -48 -10 -59 -16 -29 4 -59 38 -58 15 0 21 3 15 6 -9 3 -13 29 -13 79 0 66
2 74 19 74 17 0 20 9 23 84 3 76 1 84 -14 81 -10 -2 -18 0 -18 6 0 5 7 9 15 9
17 0 20 26 4 35 -6 4 -13 -2 -16 -14 -4 -17 -8 -19 -19 -10 -7 6 -18 8 -24 4
-6 -4 -10 4 -10 19 0 14 5 26 11 26 5 0 7 -4 4 -10 -3 -5 3 -10 15 -10 12 0
18 5 15 10 -4 6 5 10 19 10 14 0 26 5 26 10 0 6 -1 10 -2 9 -2 -1 -14 -4 -28
-9z"/>
<path d="M8665 780 c-3 -6 -5 -99 -4 -208 2 -240 2 -242 12 -242 4 0 7 104 7
230 0 127 -2 230 -4 230 -3 0 -8 -5 -11 -10z"/>
<path d="M7565 768 c-2 -7 -11 -38 -18 -68 -8 -30 -29 -100 -45 -155 -17 -55
-34 -113 -38 -130 l-6 -30 -8 25 c-4 14 -15 52 -25 85 -17 60 -42 126 -29 78
3 -13 1 -23 -5 -23 -6 0 -10 -15 -10 -32 1 -24 3 -27 6 -10 6 32 23 27 23 -8
0 -16 5 -30 10 -30 6 0 8 -11 4 -25 -4 -15 -1 -30 9 -40 8 -8 17 -20 20 -27 7
-21 20 -1 28 44 13 69 27 116 28 92 1 -12 -3 -34 -8 -50 -5 -16 -12 -44 -15
-61 -5 -30 -3 -33 20 -33 20 0 25 4 20 16 -3 9 -6 22 -6 30 0 9 -4 12 -10 9
-12 -7 -13 15 -2 33 6 8 13 -8 22 -45 l13 -58 19 44 c10 24 16 46 13 50 -3 3
-8 -5 -12 -17 -3 -12 -9 -22 -14 -22 -16 0 -9 16 17 41 14 13 30 32 36 42 9
17 8 17 -21 2 -17 -8 -31 -22 -31 -30 0 -8 -4 -15 -10 -15 -16 0 -11 36 8 52
9 9 26 18 37 21 18 4 18 5 -3 6 -22 1 -22 2 -7 31 9 17 19 30 24 30 12 0 13
98 0 106 -8 4 -9 -3 -4 -27 4 -25 3 -30 -4 -19 -13 18 -14 50 -2 68 6 10 13 8
32 -7 13 -11 19 -20 13 -21 -7 0 -11 -18 -9 -50 1 -27 -2 -50 -6 -50 -5 0 -9
-17 -9 -37 1 -34 4 -28 35 52 41 106 44 135 15 135 -25 0 -26 -16 -2 -29 16
-9 16 -11 -2 -28 -17 -16 -18 -16 -13 -1 4 11 -3 24 -21 38 -22 18 -24 23 -12
30 13 9 13 10 0 11 -8 1 1 5 20 9 34 7 34 7 -13 9 -30 1 -49 -4 -52 -11z m9
-64 c-12 -57 -12 -67 1 -80 13 -14 13 -17 0 -30 -8 -9 -15 -22 -15 -30 0 -8
-9 -23 -19 -33 -26 -26 -32 -8 -18 52 9 40 13 45 25 34 13 -10 14 -8 7 12 -7
24 14 141 26 141 4 0 0 -30 -7 -66z"/>
<path d="M7693 748 c-1 -19 1 -24 4 -13 3 11 10 26 15 33 8 8 6 12 -4 12 -9 0
-14 -12 -15 -32z"/>
<path d="M7767 773 c-10 -9 -9 -83 1 -83 5 0 7 13 4 29 -2 16 1 32 7 35 6 4
11 11 11 17 0 10 -14 12 -23 2z"/>
<path d="M8205 623 c0 -87 3 -142 6 -123 l6 35 2 -38 c1 -26 5 -36 13 -31 8 5
9 2 5 -10 -4 -10 -7 -21 -7 -24 0 -4 -5 0 -10 8 -8 13 -10 13 -10 -2 0 -32 23
-67 62 -94 31 -22 50 -27 98 -27 49 0 65 4 97 28 21 16 40 33 41 37 9 21 32 6
32 -21 0 -28 2 -29 55 -33 30 -1 55 0 55 5 0 4 -22 7 -49 7 -45 0 -48 2 -48
26 0 38 -18 51 -41 31 -10 -9 -22 -14 -25 -10 -4 3 -7 -1 -7 -10 0 -9 -4 -17
-10 -17 -5 0 -10 7 -10 15 0 8 -9 15 -20 15 -22 0 -27 -15 -7 -23 6 -3 -22 -6
-63 -7 -65 -2 -71 -3 -40 -11 19 -5 50 -6 68 -3 23 3 32 2 27 -5 -10 -17 -112
-13 -140 5 -14 9 -22 20 -19 25 3 5 -2 9 -10 9 -8 0 -17 8 -21 19 -6 21 3 51
16 51 5 0 9 11 9 25 0 17 -4 23 -14 19 -20 -8 -28 55 -24 181 l4 100 2 -121
c2 -98 5 -123 17 -128 12 -4 15 7 15 67 0 106 9 147 34 157 14 6 16 9 6 9 -22
1 -40 -19 -40 -45 0 -13 -4 -26 -10 -29 -6 -4 -10 10 -10 34 0 35 3 41 25 47
14 3 32 4 40 0 20 -7 20 -72 0 -80 -11 -4 -15 -23 -15 -77 0 -62 2 -71 20 -76
11 -3 20 -13 21 -22 0 -10 4 -7 10 8 5 13 8 76 7 140 l-3 116 -70 3 -70 3 0
-158z m132 -35 c-2 -35 -3 -7 -3 62 0 69 1 97 3 63 2 -35 2 -91 0 -125z m-20
-15 c-2 -21 -4 -4 -4 37 0 41 2 58 4 38 2 -21 2 -55 0 -75z"/>
<path d="M8006 743 c-12 -12 -6 -33 9 -33 8 0 15 6 15 14 0 17 -14 28 -24 19z"/>
<path d="M8295 720 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"/>
<path d="M7340 676 c0 -15 28 -62 38 -64 15 -3 8 33 -10 56 -10 11 -28 17 -28
8z"/>
<path d="M8550 618 c0 -35 -2 -81 -5 -103 l-5 -40 14 30 c17 34 22 175 6 175
-6 0 -10 -28 -10 -62z"/>
<path d="M7271 644 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M7297 604 c-12 -13 -7 -20 13 -20 11 0 19 5 17 10 -4 13 -21 18 -30
10z"/>
<path d="M7360 593 c0 -5 5 -15 10 -23 8 -12 10 -11 10 8 0 12 -4 22 -10 22
-5 0 -10 -3 -10 -7z"/>
<path d="M7341 554 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M7283 530 c25 -68 51 -119 58 -114 8 5 14 -24 10 -53 -1 -10 22 -14
86 -15 l88 -2 -85 -6 -85 -6 93 -2 c50 -1 92 2 92 7 0 15 -29 21 -106 21 -41
0 -73 3 -72 8 1 4 -1 25 -6 46 -5 26 -10 35 -16 27 -5 -9 -12 -1 -20 25 -10
28 -10 39 -1 42 6 2 11 8 11 14 0 6 -6 8 -14 5 -7 -3 -19 0 -26 6 -10 9 -12 9
-7 -3z"/>
<path d="M7336 502 c-5 -18 -5 -36 -2 -39 3 -4 6 2 6 12 0 16 2 15 20 -5 13
-15 20 -37 20 -62 0 -24 4 -37 10 -33 15 9 12 65 -5 81 -8 9 -15 27 -15 40 0
14 -4 23 -9 20 -5 -4 -11 0 -14 6 -2 7 -7 -2 -11 -20z"/>
<path d="M8620 490 c0 -16 2 -30 4 -30 2 0 6 14 8 30 3 17 1 30 -4 30 -4 0 -8
-13 -8 -30z"/>
<path d="M8320 476 c0 -7 -3 -34 -6 -60 -4 -26 -3 -46 3 -46 5 0 9 27 10 60 1
33 0 60 -2 60 -3 0 -5 -6 -5 -14z"/>
<path d="M8343 458 c3 -16 0 -28 -4 -28 -16 0 -9 -22 13 -41 11 -11 28 -19 37
-19 10 0 6 7 -11 18 -16 9 -28 23 -28 31 0 12 3 12 18 -1 35 -31 142 -28 142
4 0 9 -3 9 -12 0 -16 -16 -99 -15 -120 2 -10 7 -22 24 -28 37 -10 24 -10 24
-7 -3z"/>
<path d="M8538 448 c-10 -9 -13 -20 -9 -23 10 -6 34 21 29 33 -1 4 -11 0 -20
-10z"/>
<path d="M8061 394 c-18 -21 -7 -29 12 -9 14 13 16 13 19 -3 3 -14 -8 -19 -57
-29 l-60 -12 65 6 c36 3 66 6 67 7 1 0 -1 13 -4 29 -6 30 -21 34 -42 11z"/>
<path d="M8631 390 c0 -8 4 -22 9 -30 12 -18 12 -2 0 25 -6 13 -9 15 -9 5z"/>
</g>
</svg>

    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>
}
